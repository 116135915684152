//@ts-check
import React from "react"
import NavbarLIP from "../components/LinkedInPowertools/NavbarLIP"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import AuthWrapper from "../components/AuthWrapper"

import PricingSignedin from "../components/SEOAgency/PricingSignedin"

const Memberships = () => {
  return (
    <AuthWrapper>
      <Layout>
        <NavbarLIP />
        <PricingSignedin />
        <Footer />
      </Layout>
    </AuthWrapper>
  )
}

export default Memberships
